import { SUBSCRIPTIONS, WORKING_HOURS } from "../utils/data";
import { DateDifference } from "../utils/date";
import { auth, firestore, storageRef } from "./config";
import axios from 'axios';

const getCountryOrClub = (id, data) => {
  const i = data.findIndex((item) => item.docId === id);
  return data[i];
};

export const uploadEmailFromAuth = async (email, newEmail, pass) => {
  const userCredential = await auth.signInWithEmailAndPassword(email, pass);
  await userCredential.user.updateEmail(newEmail);
};
export const uploadPDF = async (image) => {
  const uid = Date.now();
  const storageRefrence = storageRef.ref("/Fanspot/menue").child("/" + uid + ".pdf");
  const uploadedFile = await storageRefrence.put(image);
  const imageUrl = await uploadedFile.ref.getDownloadURL();
  return imageUrl;
};
export const uploadFile = async (image) => {
  const uid = Date.now();
  const storageRefrence = storageRef.ref("/Fanspot").child("/" + uid + ".png");
  const uploadedFile = await storageRefrence.put(image);
  const imageUrl = await uploadedFile.ref.getDownloadURL();
  return imageUrl;
};

export const loginAdmin = async (data) => {
  const authRes = await auth.signInWithEmailAndPassword(
    data.email,
    data.password
  );
  const res = await firestore
    .collection("admins")
    .doc(authRes.user.uid)
    .get();
  if (!res.exists) {
    alert("Invalid Admin");
    return false;
  }
  sessionStorage.setItem("fanspot-admin", JSON.stringify(res.data()));
  return true;
};
export const loginVenuePortal = async (data) => {
  const authRes = await auth.signInWithEmailAndPassword(
    data.email,
    data.password
  );
  // if (!authRes.user.emailVerified) {
  //   alert(
  //     "Your email isn't verified, please open your mail and verify your account!"
  //   );
  //   return false;
  // }
  const res = await firestore
    .collection("venuePortals")
    .doc(authRes.user.uid)
    .get();
  if (!res.exists) {
    alert("Invalid Credentials");
    return false;
  }
  if (!res.data().status) {
    alert("Your account is blocked from admin.");
    return false;
  }
  if (res.data().isDeleted) {
    alert("Your account is Deleted.");
    return false;
  }
  const venueData = res.data();
  if (
    DateDifference.inDays(
      new Date(),
      venueData.subscriptionExpiryDate
        ? new Date(venueData.subscriptionExpiryDate)
        : new Date()
    ) <= 0
  ) {
    const collectionRef = firestore
      .collection("venuePortals")
      .doc(authRes.user.uid);
    let subscriptionExpiryDate = new Date();
    const subscriptionBundle = SUBSCRIPTIONS[0].name;
    const subscriptions = [SUBSCRIPTIONS[0].name];
    subscriptionExpiryDate = new Date(
      subscriptionExpiryDate.setFullYear(
        subscriptionExpiryDate.getFullYear() + 1
      )
    ).toLocaleDateString();
    await collectionRef.update({
      subscriptionExpiryDate,
      subscriptions,
      subscriptionBundle,
    });
    sessionStorage.setItem(
      "fanspot-portal",
      JSON.stringify({
        ...venueData,
        subscriptionExpiryDate,
        subscriptions,
        subscriptionBundle,
      })
    );
    return true;
  } else {
    sessionStorage.setItem("fanspot-portal", JSON.stringify(res.data()));
    return true;
  }
};

export const registerVenuePortal = async (data, isAddedByAdmin = false) => {
  const accDetailsRef = firestore
    .collection("venuePortals")
    .where("email", "==", data.email);
  let accDetails = await accDetailsRef.get();
  if (accDetails.docs.length > 0) {
    const data = accDetails.docs[0].data();
    await firestore
      .collection("venuePortals")
      .doc(data.userId)
      .update({
        isDeleted: false,
        adminVerified: false,
        subscriptionBundle: "Basic",
        subscriptions: ["Basic"],
      });
    alert(
      "Your account was already registered, we activate your account and now you can login. "
    );
  } else {
    // Register
    const authRes = await auth.createUserWithEmailAndPassword(
      data.email,
      data.password
    );
    await authRes.user.sendEmailVerification();
    const ref = firestore.collection("venuePortals").doc(authRes.user.uid);
    delete data["confirmPassword"];
    let dataCopy = { ...data };
    if (!isAddedByAdmin) {
      dataCopy = {
        ...data,
        venuePhoneNumber: "",
        website: "",
        menue: null,
        facebookLink: "",
        instagramLink: "",
        twitterLink: "",
        snapchatLink: "",
        numberOfScreens: 1,
        wifi: false,
        commentry: false,
        projector: false,
        servesFood: false,
        outdoorSeating: false,
        tableBooking: false,
        shisha: false,
        privateRoom: false,
        servesCofee: false,
        seatBooking: false,
        workingHours: WORKING_HOURS,
        logo: null,
        pictures: [],
      };
    } else {
      dataCopy = {
        ...data,
        venuePhoneNumber: "",
        website: "",
        menue: null,
        facebookLink: "",
        instagramLink: "",
        twitterLink: "",
        snapchatLink: "",
        numberOfScreens: 1,
        wifi: false,
        commentry: false,
        projector: false,
        servesFood: false,
        outdoorSeating: false,
        tableBooking: false,
        shisha: false,
        privateRoom: false,
        servesCofee: false,
        seatBooking: false,
        workingHours: WORKING_HOURS,
        isTrialReqTaken: false,
      };
    }
    if (dataCopy.logo && dataCopy.logo.size) {
      dataCopy.logo = await uploadFile(dataCopy.logo);
    }
    let picsCopy = [];
    if (dataCopy.pictures && dataCopy.pictures.length) {
      picsCopy = [...dataCopy.pictures];
      for (let i = 0; i < dataCopy.pictures.length; i++) {
        const item = picsCopy[i];
        if (item.image.size) {
          const url = await uploadFile(item.image);
          picsCopy[i] = {
            image: url,
            id: Math.random(),
          };
        }
      }
    }
    dataCopy.pictures = picsCopy;
    await ref.set({ ...dataCopy, userId: authRes.user.uid });

    sessionStorage.setItem(
      "fanspot-portal",
      JSON.stringify({ ...dataCopy, userId: authRes.user.uid })
    );
    return { ...dataCopy, userId: authRes.user.uid };
  }
};
export const uploadToDb = async (results) => {
  await firestore
    .collection("settings")
    .doc("settings")
    .set({
      resgions: results,
    });
};
export const getRegions = async () => {
  const res = await firestore
    .collection("settings")
    .doc("settings")
    .get();
  return res.data();
};
export const adminUpdatedVenuePortal = async (data) => {
  const ref = firestore.collection("venuePortals").doc(data.userId);
  const dataCopy = { ...data };
  if (dataCopy.logo && dataCopy.logo.size) {
    dataCopy.logo = await uploadFile(dataCopy.logo);
  }
  let picsCopy = [];
  if (dataCopy.pictures) {
    picsCopy = [...dataCopy.pictures];
    for (let i = 0; i < dataCopy.pictures.length; i++) {
      const item = picsCopy[i];
      if (item.image.size) {
        const url = await uploadFile(item.image);
        picsCopy[i] = {
          image: url,
          id: Math.random(),
        };
      }
    }
  }
  dataCopy.pictures = picsCopy;
  await ref.update(dataCopy);
};
export const updatedVenuePortalImages = async (data) => {
  const ref = firestore.collection("venuePortals").doc(data.userId);
  const dataCopy = { ...data };
  if (dataCopy.logo && dataCopy.logo.size) {
    dataCopy.logo = await uploadFile(dataCopy.logo);
  }
  let picsCopy = [];
  if (dataCopy.pictures) {
    picsCopy = [...dataCopy.pictures];
    for (let i = 0; i < dataCopy.pictures.length; i++) {
      const item = picsCopy[i];
      if (item.image.size) {
        const url = await uploadFile(item.image);
        picsCopy[i] = {
          image: url,
          id: Math.random(),
        };
      }
    }
  }
  dataCopy.pictures = picsCopy;
  await ref.update(dataCopy);
  const user = await ref.get();
  sessionStorage.setItem("fanspot-portal", JSON.stringify(user.data()));
};

export const updatedVenuePortalInfo = async (data) => {
  const ref = firestore.collection("venuePortals").doc(data.userId);
  const dataCopy = { ...data };
  if (dataCopy.menue && dataCopy.menue.size) {
    if (dataCopy.menue.type.includes("application/pdf")) {
      dataCopy.menue = await uploadPDF(dataCopy.menue);
    } else {
      dataCopy.menue = await uploadFile(dataCopy.menue);
    }
  }
  await ref.update(dataCopy);
  const user = await ref.get();
  sessionStorage.setItem("fanspot-portal", JSON.stringify(user.data()));
};
export const updatedVenuePortalFeatures = async (data) => {
  const ref = firestore.collection("venuePortals").doc(data.userId);
  const dataCopy = { ...data };
  delete dataCopy["menue"];
  delete dataCopy["logo"];
  delete dataCopy["pictures"];
  await ref.update(dataCopy);
  const user = await ref.get();
  sessionStorage.setItem("fanspot-portal", JSON.stringify(user.data()));
};

export const verifyUser = async (id, status) => {
  await firestore
    .collection("fanspotUserCollection")
    .doc(id)
    .update({ isVerified: status });
};
export const bookingBlock = async (id, status) => {
  await firestore
    .collection("fanspotUserCollection")
    .doc(id)
    .update({ bookingBlock: status });
};
export const verifyPortal = async (id, status) => {
  await firestore
    .collection("venuePortals")
    .doc(id)
    .update({ adminVerified: status });
};
export const blockPortalHandler = async (id, status) => {
  await firestore
    .collection("venuePortals")
    .doc(id)
    .update({ status });
};
export const getUnverifiedPortals = async () => {
  const ref = firestore
    .collection("venuePortals")
    .where("adminVerified", "==", false);
  const res = await ref.get();
  return res.docs.map((item) => ({ ...item.data(), docId: item.id }));
};
export const getVerifiedPortals = async () => {
  const ref = firestore.collection("venuePortals");
  const res = await ref.get();
  const countries = await getCountries();
  const verified = [];
  for (let i = 0; i < res.docs.length; i++) {
    const item = res.docs[i].data();
    item.country = getCountryOrClub(item.country, countries);
    if (item.adminVerified) {
      verified.push(item);
    }
  }
  return verified;
};
export const getPortals = async () => {
  const ref = firestore.collection("venuePortals");
  const res = await ref.get();
  const unVerified = [];
  const verified = [];
  for (let i = 0; i < res.docs.length; i++) {
    const item = res.docs[i].data();
    if (!item.adminVerified) {
      unVerified.push(item);
    } else {
      verified.push(item);
    }
  }
  return {
    unVerified,
    verified,
  };
};
export const getVenueByEmail = async (email) => {
  const ref = firestore.collection("venuePortals").where("email", '==', email);
  const res = await ref.get();
  return res.docs.map((item) => item.data())[0];
};
export const deletePortalMenue = async (id, menue) => {
  await storageRef.refFromURL(menue).delete();
  await firestore.collection("venuePortals").doc(id).update({ menue: null });
};
export const updateVenuePass = async (id, password) => {
  await firestore.collection("venuePortals").doc(id).update({ password });
};
export const getAllPortals = async () => {
  const ref = firestore.collection("venuePortals");
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};

export const getCountries = async () => {
  const ref = firestore.collection("countries");
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};

export const registerCountry = async (data) => {
  const ref = firestore.collection("countries").doc();
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.set({ ...data, docId: ref.id });
};

export const updateCountry = async (data) => {
  const ref = firestore.collection("countries").doc(data.id);
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.update(data);
};

export const deleteCountry = async (id) => {
  await firestore
    .collection("countries")
    .doc(id)
    .delete();
};
export const getLeagues = async () => {
  const ref = firestore.collection("clubs");
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};
export const getClubss = async () => {
  const ref = firestore.collection("clubs").orderBy('sortIndex');
  const res = await ref.get();
  const countries = await getCountries();

  return res.docs.map((item) => ({
    ...item.data(),
    country: getCountryOrClub(item.data().countryId, countries),
  }));
};

export const registerClub = async (data) => {
  const ref = firestore.collection("clubs").doc();
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.set({ ...data, docId: ref.id });
};

export const updateClub = async (data) => {
  const ref = firestore.collection("clubs").doc(data.id);
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.update(data);
};

export const deleteClub = async (id) => {
  await firestore
    .collection("clubs")
    .doc(id)
    .delete();
};
export const approveRestReq = async (id, offerReq) => {
  await firestore
    .collection("restaurantCollection")
    .doc(id)
    .update({ offerReq });
};
export const getAllTeams = async () => {
  const ref = firestore.collection("teams");
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};
export const getTeams = async () => {
  const ref = firestore.collection("teams");
  const res = await ref.get();
  const clubs = await getClubss();

  return res.docs.map((item) => ({
    ...item.data(),
    countryOrClub: getCountryOrClub(item.data().countryOrClubId, clubs),
  }));
};
export const getTeamsAndClubsForMatches = async () => {
  let countries = [];
  let clubs = [];
  const res = await getTeams();
  const allClubs = await getClubss();
  countries = res.filter((item) => item.isNationalTeam);
  clubs = res.filter((item) => !item.isNationalTeam);

  return { countries, clubs, allClubs };
};

export const getAllLeagues = async () => {
  const ref = await firestore.collection("clubs").get();
  const teamsRef = await firestore.collection("teams").get();
  return {
    leagues: ref.docs.map((item) => item.data()).sort((a, b) => a.name.localeCompare(b.name)),
    teams: teamsRef.docs.map((item) => item.data()).sort((a, b) => a.name.localeCompare(b.name)),
  };
};

export const getTeamsAndClubs = async () => {
  const countries = await getCountries();
  const allClubs = await getClubss();

  return { countries, clubs: allClubs, allClubs };
};

export const registerTeam = async (data) => {
  const ref = firestore.collection("teams").doc();
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.set({ ...data, docId: ref.id });
};

export const updateteam = async (data) => {
  const ref = firestore.collection("teams").doc(data.id);
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.update(data);
};

export const deleteTeam = async (id) => {
  await firestore
    .collection("teams")
    .doc(id)
    .delete();
};

export const getAllMatches = async () => {
  const ref = firestore.collection("matches");
  const res = await ref.get();
  const result = await getTeamsAndClubsForMatches();

  const matches = res.docs.map((item) => {
    return {
      ...item.data(),
      firstTeam: getCountryOrClub(
        item.data().firstTeamId,
        item.data().isNationalMatch ? result.countries : result.clubs
      ),
      secondTeam: getCountryOrClub(
        item.data().secondTeamId,
        item.data().isNationalMatch ? result.countries : result.clubs
      ),
    };
  });
  return matches;
};
export const getMatches = async () => {
  const ref = firestore.collection("matches");
  const res = await ref.get();
  const leaguesRef = await firestore.collection("clubs").get();
  const teamsRef = await firestore.collection("teams").get();
  const leagues = leaguesRef.docs.map((it) => it.data());
  const teams = teamsRef.docs.map((it) => it.data());
  return res.docs.map((item) => ({
    ...item.data(),
    league: leagues.find((it) => it.docId === item.data().clubId),
    firstTeam: teams.find((it) => it.docId === item.data().firstTeamId),
    secondTeam: teams.find((it) => it.docId === item.data().secondTeamId),
  }));
};

export const updateReservation = async (id, reservations) => {
  const ref = firestore.collection("matches").doc(id);
  await ref.update({ reservations });
};

export const registerMatch = async (data) => {
  const ref = firestore.collection("matches").doc();
  await ref.set({ ...data, docId: ref.id, reservations: [] });
};

export const updateMatch = async (data) => {
  const ref = firestore.collection("matches").doc(data.id);
  await ref.update(data);
};

export const deleteMatch = async (id) => {
  await firestore
    .collection("matches")
    .doc(id)
    .delete();
};

export const getRestaurants = async () => {
  const ref = firestore.collection("restaurantCollection");
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};

export const addRestaurant = async (data) => {
  const ref = firestore.collection("restaurantCollection").doc();
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }

  let picsCopy = [];
  if (data.images) {
    picsCopy = [...data.images];
    for (let i = 0; i < data.images.length; i++) {
      const item = picsCopy[i];
      if (item.image.size) {
        const url = await uploadFile(item.image);
        picsCopy[i] = {
          image: url,
          id: Math.random(),
        };
      }
    }
  }
  data.images = picsCopy;

  await ref.set({ ...data, docId: ref.id });
};

export const updateRestaurant = async (data) => {
  const ref = firestore.collection("restaurantCollection").doc(data.docId);
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  let picsCopy = [];
  if (data.images) {
    picsCopy = [...data.images];
    for (let i = 0; i < data.images.length; i++) {
      const item = picsCopy[i];
      if (item.image.size) {
        const url = await uploadFile(item.image);
        picsCopy[i] = {
          image: url,
          id: Math.random(),
        };
      }
    }
  }
  data.images = picsCopy;
  await ref.update(data);
};

export const getHomeData = async () => {
  const matches = await getMatches();
  const vanuues = await getVerifiedPortals();
  return { matches, vanuues };
};

export const deleteRestaurant = async (id) => {
  await firestore
    .collection("restaurantCollection")
    .doc(id)
    .delete();
};

export const getOffers = async () => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const ref = firestore
    .collection("offersCollection")
    .where("venueID", "==", portal.userId);
  const res = await ref.get();
  return res.docs.map((item) => item.data());
};

export const addOffer = async (data) => {
  const ref = firestore.collection("offersCollection").doc();
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.set({ ...data, docID: ref.id });
};

export const updateOffer = async (data) => {
  const ref = firestore.collection("offersCollection").doc(data.docID);
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.update(data);
};

export const deleteOffer = async (id) => {
  await firestore
    .collection("offersCollection")
    .doc(id)
    .delete();
};

export const getTableBookings = async () => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const settingref = await firestore
    .collection("tableBookingManagement")
    .doc(portal.userId)
    .get();
  const ref = firestore
    .collection("bookingCollection")
    .where("venueID", "==", portal.userId);
  const res = await ref.get();
  const bookings = [];
  for (let i = 0; i < res.docs.length; i++) {
    const item = res.docs[i].data();
    const match = await getMatch(item.matchId);
    const user = await getSingleUSer(item.userID);
    bookings.push({
      ...item,
      match: match,
      user,
    });
  }
  return {
    bookings,
    setting: settingref.data(),
  };
};

export const getUltrasBookings = async () => {
  const ref = firestore
    .collection("ultraBookingCollection");
  const res = await ref.get();
  return res.docs.map((it) => it.data())
}
export const getVisitedVenues = async () => {
  const ref = firestore
    .collection("venueVisitCollection");
  const res = await ref.get();
  return res.docs.map((it) => it.data())
}
export const getRecentReviews = async () => {
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const ref = firestore
    .collection("ratingReviewCollection")
    .where("time", ">", tenDaysAgo.getTime());
  const res = await ref.get();
  return res.docs.map((it) => it.data())
}
export const getUpcommingBooking = async () => {
  const ref = firestore
    .collection("bookingCollection")
    .where("time", ">", new Date().getTime());
  const res = await ref.get();
  return res.docs.map((it) => it.data())
}
export const getAllOffers = async () => {
  const ref = firestore
    .collection("offersCollection");
  const res = await ref.get();
  return res.docs.map((it) => it.data())
}
export const getBookings = async () => {
  const ref = firestore
    .collection("bookingCollection");
  const res = await ref.get();
  return res.docs.map((it) => it.data())
}

const getMatch = async (id) => {
  const ref = await firestore
    .collection("matches")
    .doc(id)
    .get();
  if (!ref.data()) return null;
  const firstTeam = await firestore
    .collection("teams")
    .doc(ref.data().firstTeamId)
    .get();
  const secondTeam = await firestore
    .collection("teams")
    .doc(ref.data().secondTeamId)
    .get();
  return {
    ...ref.data(),
    firstTeam: firstTeam.data(),
    secondTeam: secondTeam.data(),
  };
};

export const getDashboardData = async () => {
  const ref = firestore.collection("venuePortals");
  const res = await ref.get();
  const ref1 = firestore.collection("fanspotUserCollection");
  const res1 = await ref1.get();
  return {
    venues: res.docs.map((item) => item.data()),
    users: res1.docs.map((item) => item.data()),
  };
};

export const registerUser = async (values) => {
  const authRef = await auth.createUserWithEmailAndPassword(
    values.email,
    values.password
  );
  if (values.image && values.image.size) {
    values.image = await uploadFile(values.image);
  }
  const ref1 = firestore
    .collection("fanspotUserCollection")
    .doc(authRef.user.uid);

  await ref1.set({ ...values, userID: authRef.user.uid });
  sessionStorage.setItem(
    "fanspot-user",
    JSON.stringify({ ...values, userID: authRef.user.uid })
  );
};

export const loginUser = async (data) => {
  const authRes = await auth.signInWithEmailAndPassword(
    data.email,
    data.password
  );
  const res = await firestore
    .collection("fanspotUserCollection")
    .doc(authRes.user.uid)
    .get();
  if (!res.exists) {
    alert("Invalid User");
    return false;
  }
  if (!res.data().status) {
    alert("Your acccound is blocked from admin.");
    return false;
  }
  sessionStorage.setItem("fanspot-user", JSON.stringify(res.data()));
  return true;
};

export const tablebookingSetting = async (data) => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const ref = firestore.collection("tableBookingManagement").doc(portal.userId);
  await ref.set({ ...data, docId: ref.id });
};

export const getReviews = async (data) => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const ref = await firestore
    .collection("ratingReviewCollection")
    .where("giveToID", "==", portal.userId)
    .get();

  const res = [];
  for (let i = 0; i < ref.docs.length; i++) {
    const item = ref.docs[i];
    const userref = await firestore
      .collection("fanspotUserCollection")
      .doc(item.data().giveByID)
      .get();
    res.push({
      ...item.data(),
      user: userref.data(),
    });
  }
  return res;
};

export const setReviewReply = async (id, reply) => {
  await firestore
    .collection("ratingReviewCollection")
    .doc(id)
    .update({ reply });
};
export const getVenueSuggestions = async () => {
  const res = await firestore.collection("missingVenueCollection").get();
  return res.docs.map((item) => item.data());
};
export const submitMessageToUser = async (id, reply) => {
  await firestore
    .collection("missingVenueCollection")
    .doc(id)
    .update({ reply });
};
export const deleteSuggestion = async (id) => {
  await firestore
    .collection("missingVenueCollection")
    .doc(id)
    .delete();
};
export const suggestionHandlerInDb = async (id, data) => {
  await firestore
    .collection("matches")
    .doc(id)
    .update({ ...data });
};
export const updateAccountSetting = async (data) => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const upportal = { ...portal, ...data };
  await firestore
    .collection("venuePortals")
    .doc(portal.userId)
    .update({ ...data });
  sessionStorage.setItem("fanspot-portal", JSON.stringify(upportal));
};
export const sendPassMail = async (email) => {
  const ref = firestore.collection("venuePortals").where("email", '==', email);
  const res = await ref.get();
  if (res.docs.length < 1) {
    alert("There is no user with this email");
    return false;
  } else {
    await auth.sendPasswordResetEmail(email);
    return true;
  };
};
export const deleteAccountHandler = async (reason) => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  await firestore
    .collection("deleteAccountReqs")
    .doc(portal.userId)
    .set({ reason, isDeleted: false });
};
export const rejectDeleteAccountReq = async (id) => {
  await firestore
    .collection("deleteAccountReqs")
    .doc(id)
    .delete();
};
export const getDeleteAccountReq = async (id) => {
  const res = await firestore.collection("deleteAccountReqs").get();
  return res.docs.map((it) => ({ id: it.id, ...it.data() }));
};
export const deleteAccountReq = async (id, verifiedVenue) => {
  await firestore
    .collection("venuePortals")
    .doc(id)
    .update({
      isDeleted: true,
    });
  if (!verifiedVenue) {
    await firestore
      .collection("deleteAccountReqs")
      .doc(id)
      .update({
        isDeleted: true,
      });
  }
};

export const addContactUs = async (data) => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const ref = firestore.collection("cotactUs").doc();
  const createdDate = Date.now();
  await ref.set({
    ...data,
    docId: ref.id,
    venueId: portal.userId,
    createdDate,
  });
};
export const getVenueContactUs = async (data) => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const ref = await firestore
    .collection("cotactUs")
    .where("venueId", "==", portal.userId)
    .get();
  return ref.docs
    .map((it) => it.data())
    .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
};
export const deleteContactUs = async (id) => {
  await firestore
    .collection("cotactUs")
    .doc(id)
    .delete();
};

export const getContactUs = async () => {
  const ref = await firestore.collection("cotactUs").get();
  return ref.docs.map((it) => it.data());
};
export const placeOffer = async (data) => {
  const ref = firestore.collection("clientOffers").doc();
  await ref.set({ ...data, docId: ref.id });
};
export const getStatesData = async (portal) => {
  const checkref = await firestore
    .collection("checkInCollection")
    .where("venueID", "==", portal.userId)
    .get();
  const venueref = await firestore
    .collection("venueVisitCollection")
    .where("venueID", "==", portal.userId)
    .get();
  const venueMapref = await firestore
    .collection("venueMapVisitCollection")
    .where("venueID", "==", portal.userId)
    .get();
  const rateReviewref = await firestore
    .collection("ratingReviewCollection")
    .where("giveToID", "==", portal.userId)
    .get();
  const offerClaimedRef = await firestore
    .collection("offerClaimedCollection")
    .where("venueID", "==", portal.userId)
    .get();
  const searchRef = await firestore
    .collection("venueSearchCollection")
    .where("venueID", "==", portal.userId)
    .get();
  const venuebookingData = await getTableBookings();

  return {
    chekins: checkref.docs.map((item) => item.data()),
    venuesVisited: venueref.docs.map((item) => item.data()),
    venuesVisitedMap: venueMapref.docs.map((item) => item.data()),
    rateReviews: rateReviewref.docs.map((item) => item.data()),
    offerClaimed: offerClaimedRef.docs.map((item) => item.data()),
    venueSearch: searchRef.docs.map((item) => item.data()),
    bookings: venuebookingData.bookings,
  };
};
export const getStatesForAdminData = async () => {
  const checkref = await firestore.collection("checkInCollection").get();
  const bookingCollectionRef = await firestore
    .collection("bookingCollection")
    .get();
  const venueref = await firestore.collection("venueVisitCollection").get();
  const venueMapref = await firestore
    .collection("venueMapVisitCollection")
    .get();
  const rateReviewref = await firestore
    .collection("ratingReviewCollection")
    .get();
  const offerClaimedRef = await firestore
    .collection("offerClaimedCollection")
    .get();
  const searchRef = await firestore.collection("venueSearchCollection").get();

  return {
    chekins: checkref.docs.map((item) => item.data()),
    venuesVisited: venueref.docs.map((item) => item.data()),
    venuesVisitedMap: venueMapref.docs.map((item) => item.data()),
    rateReviews: rateReviewref.docs.map((item) => item.data()),
    offerClaimed: offerClaimedRef.docs.map((item) => item.data()),
    venueSearch: searchRef.docs.map((item) => item.data()),
    bookings: bookingCollectionRef.docs.map((item) => item.data()),
  };
};

export const getUsers = async () => {
  const res = await firestore.collection("fanspotUserCollection").get();
  return res.docs.map((item) => item.data());
};

export const addUser = async (data) => {
  const accDetailsRef = firestore
    .collection("fanspotUserCollection")
    .where("email", "==", data.email);
  let accDetails = await accDetailsRef.get();
  if (accDetails.docs.length > 0) {
    const data = accDetails.docs[0].data();
    await firestore
      .collection("fanspotUserCollection")
      .doc(data.userId)
      .update({
        isDeleted: false,
      });
    alert(
      "This account was already registered, we activate this account and now you he/she can login. "
    );
  } else {
    const authRes = await auth.createUserWithEmailAndPassword(
      data.email,
      data.password
    );
    const ref = firestore
      .collection("fanspotUserCollection")
      .doc(authRes.user.uid);
    if (data.image && data.image.size) {
      data.image = await uploadFile(data.image);
    }
    await ref.set({ ...data, userID: ref.id });
  }
};

export const updateUser = async (data) => {
  const ref = firestore.collection("fanspotUserCollection").doc(data.userID);
  if (data.image && data.image.size) {
    data.image = await uploadFile(data.image);
  }
  await ref.update(data);
};

export const deleteUser = async (id) => {
  await firestore
    .collection("fanspotUserCollection")
    .doc(id)
    .update({ isDeleted: true });
};
export const deleteVenue = async (id) => {
  await firestore
    .collection("venuePortals")
    .doc(id)
    .delete();
};
export const deleteClientMessages = async (id) => {
  await firestore
    .collection("contactCollection")
    .doc(id)
    .delete();
};
export const addUserMessage = async (values) => {
  const ref = firestore.collection("contactCollection").doc();

  await ref.set({
    docID: ref.id,
    ...values,
  });
};
export const deletePortalMessageInDB = async (id) => {
  await firestore
    .collection("cotactUs")
    .doc(id)
    .delete();
};

export const getClientMessages = async () => {
  const ref = await firestore.collection("contactCollection").get();
  const ref1 = await firestore.collection("cotactUs").get();
  return {
    users: ref.docs.map((it) => it.data()),
    portals: ref1.docs.map((it) => it.data()),
  };
};
export const getPortalMessage = async () => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const ref = await firestore
    .collection("cotactUs")
    .doc(portal.userId)
    .get();
  return ref.data();
};
export const submitSupportMessageToUser = async (id, message, collection) => {
  await firestore
    .collection(collection)
    .doc(id)
    .update({ message });
};
export const getPredictions = async () => {
  const ref = await firestore.collection("predictionCollection").get();
  return ref.docs.map((it) => it.data())
}
export const getClientPredictions = async () => {
  const ref = await firestore.collection("predictionCollection").get();
  const teams = await getTeams();
  const matches = [];
  for (let i = 0; i < ref.docs.length; i++) {
    const item = ref.docs[i];
    const match = await getSingleMatch(item.data().matchID);
    const user = await getSingleUSer(item.data().userID);
    matches.push({
      ...item.data(),
      match,
      user,
      firstTeam: getTeam(item.data().firstTeamID, teams),
      secondTeam: getTeam(item.data().secondTeamID, teams),
    });
  }
  return matches;
};

const getTeam = (id, teams) => {
  const i = teams.findIndex((it) => it.docId === id);
  return teams[i];
};
const getSingleMatch = async (id) => {
  const ref = await firestore
    .collection("matches")
    .doc(id)
    .get();
  const match = ref.data();
  let league = null;
  if (match) {
    const ref1 = await firestore
      .collection("clubs")
      .doc(match.leagueID)
      .get();
    league = ref1.data();
  }
  return match && { ...match, league };
};
const getSingleUSer = async (id) => {
  const ref = await firestore
    .collection("fanspotUserCollection")
    .doc(id)
    .get();
  return ref.data();
};

export const getFaqs = async () => {
  const ref = await firestore.collection("faqCollection").get();
  return ref.docs.map((item) => item.data());
};

export const getClientsFaqs = async () => {
  const ref = await firestore.collection("faqCollection").get();

  const faqs = ref.docs.map((it) => it.data());
  return faqs.filter(
    (item) => item.appearIn === "Both" || item.appearIn === "Portal"
  );
};
export const setFAQanswer = async (id, answer, answerInArrabic) => {
  await firestore
    .collection("faqCollection")
    .doc(id)
    .update({
      answer,
      answerInArrabic,
    });
};
export const updateFAQ = async (id, appearIn) => {
  await firestore
    .collection("faqCollection")
    .doc(id)
    .update({
      appearIn,
    });
};
export const deleteFAQ = async (id) => {
  await firestore
    .collection("faqCollection")
    .doc(id)
    .delete();
};

export const updateFaq = async (id, question, questionInArrabic) => {
  const ref = firestore.collection("faqCollection").doc(id);
  await ref.update({
    question,
    questionInArrabic,
  });
};
export const addFaq = async (question, questionInArrabic) => {
  const ref = firestore.collection("faqCollection").doc();
  await ref.set({
    question,
    questionInArrabic,
    answer: "",
    answerInArrabic: "",
    time: Date.now(),
    docID: ref.id,
  });
};
export const addMembershipReq = async (values, userId) => {
  const res = await firestore
    .collection("membershipRequests")
    .where("userId", "==", userId)
    .get();
  if (res.docs.length) {
    alert("You already have a request in progress, please check with support”");
    return false;
  } else {
    const ref = firestore.collection("membershipRequests").doc();
    await ref.set({
      ...values,
      userId,
      docID: ref.id,
    });
    const message =
      "New Subscription request has been Added, Please have a look!";
    const route = "admin/membershipreqs";
    await addNotificationForAdmin(message, route);
    return true;
  }
};

export const getMembershipReq = async () => {
  const ref = await firestore.collection("membershipRequests").get();
  return ref.docs.map((item) => item.data());
};

export const approveReqHandler = async (item) => {
  const ref = firestore.collection("venuePortals").doc(item.userId);
  const venueRef = await ref.get();
  const venuePortal = venueRef.data();
  venuePortal.venueName = item.venueName ? item.venueName : "";
  venuePortal.subscriptionExpiryDate = item.subscriptionExpiryDate
    ? item.subscriptionExpiryDate
    : "";
  venuePortal.mangerPhoneNumber = item.mangerPhoneNumber
    ? item.mangerPhoneNumber
    : "";
  venuePortal.paymentFrequency = item.paymentFrequency
    ? item.paymentFrequency
    : "";
  venuePortal.subscriptionBundle = item.subscriptionBundle
    ? item.subscriptionBundle
    : "";
  venuePortal.isTrialReqTaken = item.isTrialReqTaken
    ? item.isTrialReqTaken
    : false;
  venuePortal.subscriptions = [
    item.subscriptionBundle ? item.subscriptionBundle : "",
  ];
  await ref.update(venuePortal);
  await firestore
    .collection("membershipRequests")
    .doc(item.docID)
    .delete();
};

export const changeSubscriptionOfPortal = async (userId, bundle) => {
  const ref = firestore.collection("venuePortals").doc(userId);
  const venueRef = await ref.get();
  const venuePortal = venueRef.data();
  venuePortal.subscriptionBundle = bundle;
  venuePortal.subscriptions = [bundle];
  let subscriptionExpiryDate = new Date();
  venuePortal.subscriptionExpiryDate = subscriptionExpiryDate = new Date(
    subscriptionExpiryDate.setFullYear(subscriptionExpiryDate.getFullYear() + 1)
  ).toLocaleDateString();
  await ref.update(venuePortal);
};

export const getVenueForDeletion = async (userId) => {
  const ref = await firestore
    .collection("venuePortals")
    .doc(userId)
    .get();
  return ref.data();
};

export const sendMessageToVenueHandler = async (id, message) => {
  await firestore
    .collection("adminMessages")
    .doc()
    .set({ venueId: id, message });
};
export const addCreditToVenueHandler = async (id, credit) => {
  await firestore
    .collection("venuePortals")
    .doc(id)
    .update({ credit: parseInt(credit) });
};
export const updateToken = async (id, token) => {
  await firestore
    .collection("deviceTokens")
    .doc(id)
    .set({ deviceTokens: token });
};
export const deleteAdminMessageInPortal = async (id) => {
  await firestore
    .collection("venuePortals")
    .doc(id)
    .update({ message: null });
};

export const getAdminMessages = async (id) => {
  const portal = JSON.parse(sessionStorage.getItem("fanspot-portal"));
  const res = await firestore
    .collection("adminMessages")
    .where("venueId", "==", portal.userId)
    .get();
  return res.docs.map((item) => item.data());
};
export const getEvents = async () => {
  const res = await firestore.collection("ultraEvents").get();
  const events = [];
  for (let i = 0; i < res.docs.length; i++) {
    const item = res.docs[i].data();
    const reslt = await firestore
      .collection("ultraBookingCollection")
      .where("ultraID", "==", item.docId)
      .get();
    events.push({
      ...item,
      users: reslt.docs.map((it) => it.data()),
    });
  }
  return events;
};

export const createEvent = async (data, logo) => {
  const ref = firestore.collection("ultraEvents").doc();
  if (logo) {
    logo = await uploadFile(logo);
  }
  await ref.set({
    ...data,
    docId: ref.id,
    users: [],
    logo,
  });
  return {
    ...data,
    docId: ref.id,
    users: [],
    logo,
  };
};
export const updateEvent = async (data, logo, id, users) => {
  const ref = firestore.collection("ultraEvents").doc(id);
  if (logo && logo.size) {
    logo = await uploadFile(logo);
  }
  await ref.update({
    ...data,
    logo,
  });
  return {
    ...data,
    logo,
    docId: id,
    users,
  };
};
export const deleteEvent = async (id) => {
  await firestore
    .collection("ultraEvents")
    .doc(id)
    .delete();
};
export const addNotificationForAdmin = async (message, route) => {
  const ref = firestore.collection("adminNotifications").doc();
  await ref.set({
    docId: ref.id,
    message,
    route,
    isRead: false,
  });
};
export const addNotificationForVenue = async (message, route, venueId) => {
  const ref = firestore.collection("venueNotifications").doc();
  await ref.set({
    docId: ref.id,
    message,
    route,
    venueid: venueId,
    isRead: false,
  });
};
export const addNotificationForUser = async (message, route, userId) => {
  const ref = firestore.collection("userNotifications").doc();
  await ref.set({
    docId: ref.id,
    message,
    route,
    userId,
    isRead: false,
  });
};
export const getUser = async (email) => {
  const res = await firestore
    .collection("fanspotUserCollection")
    .where("email", "==", email)
    .get();
  return res.docs[0] ? res.docs[0].data() : null;
};
export const getAllTokens = async (id) => {
  const res = await firestore
    .collection("deviceTokens")
    .get();
  return res.docs.map(it => ({ ...it.data(), id: it.id }));
};
export const getUserToken = async (id) => {
  const res = await firestore
    .collection("deviceTokens")
    .doc(id)
    .get();
  return res.data();
};
export const sendNotificationToUsers = async (data) => {
  const ONESIGNAL_API_KEY = "ZTcyNDA1NWQtN2RmMS00ZmI0LWE2NDQtNzcyMDg0NjQxNWUx";
  const ONESIGNAL_APP_ID = "117ff847-181f-4d42-a79c-ff8cd8403da2";
  const headers = {
    'Authorization': `Bearer ${ONESIGNAL_API_KEY}`,
    'Content-Type': 'application/json',
  };

  const requestBody = {
    app_id: ONESIGNAL_APP_ID,
    include_player_ids: data.tokens,
    contents: {
      en: data.body,
    },
    heading: { en: 'Fanspot' },
    ios_badgeType: 'Increase',
    ios_badgeCount: 1,
    subtitle: { en: data.title },
    data: {
      custom_data: null,
    },
  };

  try {
    const response = await axios.post('https://onesignal.com/api/v1/notifications', requestBody, {
      headers: headers,
    });

    if (response.status === 200) {
      console.log(response.data);
    } else {
      console.log(response.statusText);
    }
  } catch (error) {
    console.error(error);
  }
};
export const sendNotificationToUser = async (token, msg) => {
  let notibody = "Fanspot Admin responded to yur query.";
  if (msg) {
    notibody = msg;
  }
  const notititle = "Query Response";
  const ONESIGNAL_API_KEY = "ZTcyNDA1NWQtN2RmMS00ZmI0LWE2NDQtNzcyMDg0NjQxNWUx";
  const ONESIGNAL_APP_ID = "117ff847-181f-4d42-a79c-ff8cd8403da2";
  const headers = {
    'Authorization': `Bearer ${ONESIGNAL_API_KEY}`,
    'Content-Type': 'application/json',
  };

  const requestBody = {
    app_id: ONESIGNAL_APP_ID,
    include_player_ids: [token],
    contents: {
      en: notibody,
    },
    heading: { en: 'Fanspot' },
    ios_badgeType: 'Increase',
    ios_badgeCount: 1,
    subtitle: { en: notititle },
    data: {
      custom_data: null,
    },
  };

  try {
    const response = await axios.post('https://onesignal.com/api/v1/notifications', requestBody, {
      headers: headers,
    });

    if (response.status === 200) {
      console.log(response.data);
    } else {
      console.log(response.statusText);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const reneveVenueMatches = async (id) => {
  const matches = await firestore
    .collection("matches")
    .where("reservations", "array-contains", id)
    .get();
  console.log(matches);
  for (let i = 0; i < matches.docs.length; i++) {
    const match = matches.docs[i].data();
    let reservationsCopy = [...match.reservations];
    reservationsCopy = reservationsCopy.filter((item) => item !== id);
    await firestore
      .collection("matches")
      .doc(match.docId)
      .update({
        reservations: reservationsCopy,
      });
  }
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  await firestore
    .collection("venuePortals")
    .doc(id)
    .update({
      renevalPortalDate: date.toISOString(),
    });
};

export const uploadLocale = async (locale, type) => {
  const ref = firestore.collection("admins").doc("locale");
  const data = (await ref.get()).data();
  if (!type.includes("english")) {
    await ref.set({
      englishLocale: data.englishLocale,
      arrabicLocale: locale,
    });
  } else {
    await ref.set({
      arrabicLocale: data.arrabicLocale,
      englishLocale: locale,
    });
  }
};
export const getLocale = async () => {
  return (
    await firestore
      .collection("admins")
      .doc("locale")
      .get()
  ).data();
};
